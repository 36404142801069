<template>
    <div class="nonhasspace_container">
        <h2 class="title_md right_container_sub_title">내 채널</h2>
        <div class="mychannel_desc_info">
            <h1 class="title_lg desc_title">{{ name }}님의 공간에 어울리는 음악은?</h1>
            <p class="title_sm desc_description">
                스페이스 정보를 등록하면 <br />
                어울리는 음악을 추천해드려요.
            </p>
            <div class="add_space_btn_wrap">
                <button class="add_space_btn" @click="goSpaceInsert">스페이스 등록하기</button>
            </div>
        </div>

        <h2 class="title_md right_container_sub_title second">즐겨찾기</h2>
        <ul class="similarity_list scroll no_recommend" v-if="bookmarkChannelsList.length > 0">
            <li
                class="aplayz_channel mb16"
                v-for="(item, idx) in bookmarkChannelsList"
                :key="item.channelId"
                :id="`bookmark_${item.channelId}`"
            >
                <figure class="aplayz_channel_img_wrap cursor" @click="clickMyChannel(item, 'bookmark')">
                    <div class="equalizer_wrap" v-if="isplay && item.channelId === $store.getters.gettersChannelId">
                        <equaliaer-comp />
                    </div>
                    <img class="aplayz_channel_img" :src="item.imgUrl" :alt="`즐겨찾기_${idx + 1}`" />
                </figure>
                <div id="channelname_parent" class="aplayz_channel_nm_wrap">
                    <h3 class="title_sm aplayz_channel_nm cursor" @click="clickMyChannel(item, 'bookmark')">
                        {{ item.spaceNm }}
                    </h3>
                </div>

                <figure class="star_bookmark_wrap cursor" @click="setManageBookMark(item.channelId)">
                    <img src="/media/img/star_after.png" alt="즐겨찾기" class="star star_bookmark" />
                </figure>
            </li>
        </ul>
        <p v-else class="no_list">마음에 드는 채널을 즐겨찾기 추가하시면, 더 편하게 이용하실 수 있어요.</p>
    </div>
</template>
<script>
import { getUserName } from '@/service/api/userApi';
export default {
  name: 'NonHasSpace',
  props: {
    isplay: {
      type: Boolean,
      required: false,
      default: false
    },
    activetab: {
      type: String,
      requried: false,
      default: ''
    },
    clickchannel: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    },
    setmanagebookmark: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    }
  },
  created () {
    const userId = this.$cookies.get('userId');
    getUserName(userId)
      .then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.name = result;
        } else {
          this.name = '';
        }
      })
      .catch(() => {
        this.name = '';
      });
  },
  data () {
    return {
      name: ''
    };
  },
  components: {
    // dss
    'equaliaer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  mounted () {},

  methods: {
    goSpaceInsert () {
      this.$router.push({ name: 'SpaceInsert', params: { isTrialPrice: false } }).catch(() => {});
    },
    clickMyChannel (item, type) {
      this.clickchannel(item, type);
    },
    setManageBookMark (channelId) {
      this.setmanagebookmark(channelId);
    }
  },
  computed: {
    bookmarkChannelsList () {
      return this.$store.getters['player/getBookmarkChannels'];
    }
  }
};
</script>
<style scoped src="@/assets/css/player/nonhasspace.css"></style>
